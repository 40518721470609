import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IProfilePictureResponse } from '../models/users/profile-picture.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profilePictureSubject: Subject<IProfilePictureResponse | undefined | null> =
    new Subject<IProfilePictureResponse | undefined | null>();
}
