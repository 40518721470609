import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TopNavBarLinkItems } from '../../core/configs/nav-bar-link-items.config';
import IIremboUser, {
  _BLANK_PROFILE,
} from '../../core/models/irembo-user-model';
import { INavbarTopLinkItem } from '../../core/models/navbar-link-item.model';
import { AuthService } from '../../core/services/auth.service';
import { IdleTimerService } from '@irembo-andela/irembogov3-common';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { IProfilePictureResponse } from '../../core/models/users/profile-picture.model';
import { ProfileService } from '../../core/services/profile.service';

@Component({
  selector: 'irembogov-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  userProfile: IIremboUser = _BLANK_PROFILE;
  _userProfile!: Subscription;
  isLoggedIn!: boolean;

  navBarItems: INavbarTopLinkItem[] = [];

  idleState!: string;
  profilePicture?: IProfilePictureResponse;
  profilePictureSubscription = this.profilePictureService.profilePictureSubject;

  constructor(
    private authService: AuthService,
    private idleTimerService: IdleTimerService,
    private router: Router,
    private profilePictureService: ProfileService
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn();

    this.profilePictureSubscription.subscribe(profilePicture => {
      if (profilePicture) {
        this.profilePicture = profilePicture;
      }
    });

    this._userProfile = this.authService.userProfile$.subscribe(
      (profile: IIremboUser) => {
        this.userProfile = profile;
        this.getProfilePicture();
      }
    );
    await this.authService.checkLoginProfile();
    this.idleTimerService.initIdleTimer(
      environment.idleTimeOutDuration,
      environment.idleTimeOutCountDown
    );

    this.idleTimerService.idleState$.subscribe(state => {
      if (state === 'timedOut') {
        this.logoutUser();
      }
    });
  }

  logoutUser() {
    this.authService.logOutUser();
  }

  getTopNavBarLinkItemsForRole(): INavbarTopLinkItem[] {
    const navbarItems: INavbarTopLinkItem[] = [];
    Object.keys(TopNavBarLinkItems).forEach((key: string) => {
      const item: INavbarTopLinkItem = TopNavBarLinkItems[key];
      if (!item.allowedRoles) {
        navbarItems.push(item);
        return;
      }

      if (!(this.userProfile && this.userProfile.primaryRole)) return;

      if (item.allowedRoles.indexOf(this.userProfile.primaryRole.key) > -1) {
        navbarItems.push(item);
        return;
      }

      return false;
    });

    return navbarItems;
  }

  ngOnDestroy(): void {
    if (this._userProfile) this._userProfile.unsubscribe();
    this.profilePictureSubscription.unsubscribe();
  }

  handleProfileClick() {
    this.router.navigate(['/dashboard/users/account-profile']);
  }

  getProfilePicture() {
    this.authService.getProfilePicture(this.userProfile?.id ?? '').subscribe({
      next: response => {
        this.profilePicture = response.data;
      },
    });
  }
}
