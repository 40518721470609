import { IRolesEnumValuePair, RolesEnum } from './user-roles';
import { createRolesEnumValuePair } from '../utils/authUserUtils';

export interface IIremboBaseUser {
  firstName: string;
  lastName: string;
  email: string;
}
export default interface IIremboUser extends IIremboBaseUser {
  id?: string;
  roles: Array<string>;
  enabled: boolean;
  addedBy?: IIremboBaseUser;
  primaryRole: IRolesEnumValuePair;
  selectedInView?: boolean;
  emailVerified?: boolean;
  dateCreated?: number;
  username?: string;
  lastActiveDate?: number;
}

export const _BLANK_PROFILE: IIremboUser = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  roles: [],
  enabled: false,
  primaryRole: createRolesEnumValuePair(RolesEnum._UNKNOWN_ROLE_),
};
