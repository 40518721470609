import { AuthGuard } from '@irembo-andela/irembogov3-common';
import { Route } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RoleGuard } from './core/guards/role.guard';
import { NxWelcomeComponent } from './nx-welcome.component';

export const appRoutes: Route[] = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['ROLE_INTEGRATION_MANAGER', 'ROLE_INTEGRATION_OPS'] },
    children: [
      { path: '', component: NxWelcomeComponent },
      {
        path: 'endpoints',
        loadChildren: () =>
          import('./modules/endpoints/endpoints.module').then(
            m => m.EndpointsModule
          ),
      },
      {
        path: 'adapters',
        loadChildren: () =>
          import('./modules/adapters/adapters.module').then(
            m => m.AdaptersModule
          ),
      },
      {
        path: 'requests',
        loadChildren: () =>
          import('./modules/requests/requests.module').then(
            m => m.RequestsModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then(m => m.UsersModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: 'dashboard' },
];
