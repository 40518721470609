import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private keycloak: KeycloakService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.isAuthorized(route);
  }

  async isAuthorized(route: ActivatedRouteSnapshot) {
    const routeData: string[] = route.data['roles'];

    if (
      (await this.keycloak.isLoggedIn()) &&
      routeData.some(role => this.keycloak.getUserRoles().includes(role))
    ) {
      return true;
    }

    this.keycloak.logout();
    return false;
  }
}
