export enum RolesEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_PARTNERSHIP_MANAGER = 'ROLE_PARTNERSHIP_MANAGER',
  ROLE_IREMBOGOV_ADMIN = 'ROLE_IREMBOGOV_ADMIN',
  ROLE_INTEGRATION_MANAGER = 'ROLE_INTEGRATION_MANAGER',
  ROLE_SERVICE_MANAGER = 'ROLE_SERVICE_MANAGER',
  ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
  ROLE_OFFICER_ADMIN = 'ROLE_OFFICER_ADMIN',
  ROLE_QA_REVIEWER = 'ROLE_QA_REVIEWER',
  ROLE_ORGANIZATION_SUPPORT = 'ROLE_ORGANIZATION_SUPPORT',
  ROLE_ORGANIZATION_EXECUTIVE = 'ROLE_ORGANIZATION_EXECUTIVE',
  ROLE_OFFICER = 'ROLE_OFFICER',
  ROLE_OFFICER_MANAGER = 'ROLE_OFFICER_MANAGER',
  _UNKNOWN_ROLE_ = 'UNKNOWN_ROLE',
  ROLE_GOVERNMENT_EXEC = 'ROLE_GOVERNMENT_EXEC',
  ROLE_CITIZEN = 'ROLE_CITIZEN',
  ROLE_SERVICE_DISTRIBUTION_MANAGER = 'ROLE_SERVICE_DISTRIBUTION_MANAGER',
  ROLE_INTEGRATION_OPS = 'ROLE_INTEGRATION_OPS',
  ROLE_AGENT = 'ROLE_AGENT',
}

export interface IRolesEnumValuePair {
  label: string;
  key: RolesEnum;
}

export const integrationRoles: RolesEnum[] = [
  RolesEnum.ROLE_INTEGRATION_MANAGER,
  RolesEnum.ROLE_INTEGRATION_OPS,
];
