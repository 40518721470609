/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, Observable } from 'rxjs';
import IIremboUser, { _BLANK_PROFILE } from '../models/irembo-user-model';
import { IRolesEnumValuePair } from '../models/user-roles';
import { ConvertKeycloakToIremboUserProfile } from '../utils/authUserUtils';
import { HttpPagedResponse } from '../models/http-paged-response.model';
import { IUserResponse } from '../models/users/user-response.model';
import { IBaseResponseBody } from '../models/http/base-response.model';
import { environment } from '../../../environments/environment';
import { IUserRequest } from '../models/users/user-request.model';
import { IUnassignRoleRequest } from '../models/users/unassign-role-request.model';
import { IAssignRoleRequest } from '../models/users/assign-role-request.model';
import { IProfilePictureResponse } from '../models/users/profile-picture.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = false;
  private userRole!: IRolesEnumValuePair;
  private _userRole: BehaviorSubject<IRolesEnumValuePair> =
    new BehaviorSubject<IRolesEnumValuePair>(this.userRole);

  private userProfile!: IIremboUser;
  private _userProfile: BehaviorSubject<IIremboUser> =
    new BehaviorSubject<IIremboUser>(this.userProfile);

  public userProfile$: Observable<IIremboUser> =
    this._userProfile.asObservable();
  public userRole$: Observable<IRolesEnumValuePair> =
    this._userRole.asObservable();

  constructor(
    private http: HttpClient,
    private keyCloakService: KeycloakService
  ) {}

  async checkLoginProfile() {
    this.loggedIn = await this.isLoggedIn();
    this.userProfile = await this.loadUserProfile();
    this.userRole = this.userProfile.primaryRole;
    this._userRole.next(this.userRole);
    this._userProfile.next(this.userProfile);
    return this.userProfile;
  }

  isLoggedIn(): Promise<boolean> {
    return this.keyCloakService.isLoggedIn();
  }

  private async loadUserProfile(): Promise<IIremboUser> {
    if (!this.loggedIn) {
      this.userProfile = _BLANK_PROFILE;
      this._userProfile.next(this.userProfile);
      return this.userProfile;
    }
    try {
      const keycloakProfile: KeycloakProfile =
        await this.keyCloakService.loadUserProfile();
      const keycloakRolesList: string[] = this.keyCloakService.getUserRoles();

      return ConvertKeycloakToIremboUserProfile(
        keycloakProfile,
        keycloakRolesList
      );
    } catch (e) {
      console.log(e, 'Can not get user profile from keycloak service');
    }
    return _BLANK_PROFILE;
  }

  logOutUser(): void {
    this.keyCloakService.logout();
  }

  getUsersByRole(
    role: string,
    page: number = 0,
    size: number = 10,
    sortBy: string = 'createdOn',
    sortDirection: 'ASC' | 'DESC' = 'DESC'
  ): Observable<HttpPagedResponse<IUserResponse>> {
    const headers = new HttpHeaders({
      sortBy,
      sortDirection,
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/users/roles/${role}?page=${page}&size=${size}`;
    return this.http.get<HttpPagedResponse<IUserResponse>>(url, { headers });
  }

  getUserByUsername(
    username: string
  ): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({
      username: username,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/check-user-exist`;
    return this.http.get<IBaseResponseBody<IUserResponse>>(url, { headers });
  }

  getUsersById(userId: string): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({
      userId: userId,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/user-by-id`;
    return this.http.get<IBaseResponseBody<IUserResponse>>(url, { headers });
  }

  createUser(
    request: IUserRequest
  ): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user`;
    return this.http.post<IBaseResponseBody<IUserResponse>>(url, request, {
      headers,
    });
  }

  unassignUserRole(
    request: IUnassignRoleRequest
  ): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({});
    return this.http.patch<IBaseResponseBody<IUserResponse>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/unassign-role`,
      request,
      { headers }
    );
  }

  assignUserRoler(
    request: IAssignRoleRequest
  ): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({});
    return this.http.patch<IBaseResponseBody<IUserResponse>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/assign-role`,
      request,
      { headers }
    );
  }

  getProfilePicture(userId: string) {
    const headers = new HttpHeaders({
      userId: userId,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/profile-picture`;
    return this.http.get<IBaseResponseBody<IProfilePictureResponse>>(url, {
      headers,
    });
  }

  updateProfilePicture(data: FormData) {
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/update-profile-picture`;
    return this.http.put<IBaseResponseBody<IProfilePictureResponse>>(url, data);
  }
}
