export const environment = {
  production: false,
  idleTimeOutDuration: 1800,
  idleTimeOutCountDown: 30,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com',
  authRealm: 'irembo',
  authClientId: 'integration-management-portal',
  authSuccessRedirectUrl: 'https://integration-portal.uat.irembolabs.com',
  apiGatewayBaseUrl: 'https://integration-gateway.uat.irembolabs.com',
};
