import { INavbarTopLinkItem } from '../models/navbar-link-item.model';
import { RolesEnum } from '../models/user-roles';

export const TopNavBarLinkItems: Record<string, INavbarTopLinkItem> = {
  dashboard: {
    name: 'Dashboard',
    routerLink: ['/dashboard'],
    icon: 'fa-solid fa-grip',
    allowedRoles: [
      RolesEnum.ROLE_INTEGRATION_MANAGER,
      RolesEnum.ROLE_INTEGRATION_OPS,
    ],
  },
  Users: {
    name: 'Users',
    routerLink: ['/dashboard/users/users-list'],
    icon: 'fas fa-users',
    allowedRoles: [RolesEnum.ROLE_INTEGRATION_MANAGER],
  },
  Adapters: {
    name: 'Adapters',
    routerLink: ['/dashboard/adapters/adapters-list'],
    icon: 'fas fa-gear',
    allowedRoles: [RolesEnum.ROLE_INTEGRATION_MANAGER],
  },
  Endpoints: {
    name: 'Endpoints',
    routerLink: ['/dashboard/endpoints/endpoints-list'],
    icon: 'fas fa-diagram-project',
    allowedRoles: [RolesEnum.ROLE_INTEGRATION_MANAGER],
  },
  Requests: {
    name: 'Requests',
    routerLink: ['/dashboard/requests/requests-list'],
    icon: 'fas fa-code-pull-request',
    allowedRoles: [
      RolesEnum.ROLE_INTEGRATION_MANAGER,
      RolesEnum.ROLE_INTEGRATION_OPS,
    ],
  },
};
