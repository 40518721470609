import { HttpErrorResponse } from '@angular/common/http';
import { KeycloakProfile } from 'keycloak-js';
import IIremboUser from '../models/irembo-user-model';
import {
  IRolesEnumValuePair,
  RolesEnum,
  integrationRoles,
} from '../models/user-roles';

export const FindUserPrimaryRole = (roles: string[]): RolesEnum => {
  let foundUserRole: RolesEnum = RolesEnum._UNKNOWN_ROLE_;

  integrationRoles.some((r: string) => {
    if (roles.includes(r)) {
      foundUserRole = RolesEnum[r as keyof typeof RolesEnum];
      return true;
    }
    return false;
  });
  return foundUserRole;
};

export const ConvertKeycloakToIremboUserProfile = (
  keycloakProfile: KeycloakProfile,
  roles: string[]
): IIremboUser => {
  const primaryRole: RolesEnum = FindUserPrimaryRole(roles);
  let otherRoles: string[] = [];
  if (primaryRole !== RolesEnum._UNKNOWN_ROLE_) {
    otherRoles = roles.filter((role: string) => role !== primaryRole.valueOf());
  }

  return {
    firstName: keycloakProfile.firstName || '',
    lastName: keycloakProfile.lastName || '',
    id: keycloakProfile.id,
    email: keycloakProfile.email || '',
    primaryRole: { key: primaryRole, label: RoleEnumToLabelUtil(primaryRole) },
    roles: roles ? [...otherRoles] : [],
    enabled: keycloakProfile.enabled || false,
    emailVerified: keycloakProfile.emailVerified || false,
    dateCreated: keycloakProfile.createdTimestamp || undefined,
    username: keycloakProfile.username || undefined,
  };
};

export const RoleEnumToLabelUtil = (role: RolesEnum): string => {
  switch (role) {
    case RolesEnum.ROLE_SUPER_ADMIN:
      return 'Super Admin';
    case RolesEnum.ROLE_IREMBOGOV_ADMIN:
      return 'IremboGov Admin';
    case RolesEnum.ROLE_PARTNERSHIP_MANAGER:
      return 'Client Manager';
    case RolesEnum.ROLE_INTEGRATION_MANAGER:
      return 'Integration Manager';
    case RolesEnum.ROLE_SERVICE_MANAGER:
      return 'Service Manager';
    case RolesEnum.ROLE_QA_REVIEWER:
      return 'QA Reviewer';
    case RolesEnum.ROLE_ORGANIZATION_ADMIN:
      return 'Organization Admin';
    case RolesEnum.ROLE_OFFICER_ADMIN:
      return 'Officer Admin';
    case RolesEnum.ROLE_ORGANIZATION_SUPPORT:
      return 'Organization Support';
    case RolesEnum.ROLE_ORGANIZATION_EXECUTIVE:
      return 'Organization Executive';
    case RolesEnum.ROLE_OFFICER:
      return 'Officer';
    case RolesEnum.ROLE_OFFICER_MANAGER:
      return 'Officer Manager';
    case RolesEnum.ROLE_INTEGRATION_OPS:
      return 'Integration Ops';
    case RolesEnum.ROLE_GOVERNMENT_EXEC:
      return 'Government Executive';
    case RolesEnum.ROLE_CITIZEN:
      return 'Citizen';
    case RolesEnum.ROLE_AGENT:
      return 'Agent';
    case RolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER:
      return 'Service Distribution Manager';
    default:
      return '';
  }
};

export const createRolesEnumValuePair = (
  roleEnum: RolesEnum
): IRolesEnumValuePair => {
  if (!roleEnum) {
    roleEnum = RolesEnum._UNKNOWN_ROLE_;
  }

  const label: string | null = RoleEnumToLabelUtil(roleEnum);
  return {
    key: roleEnum,
    label,
  };
};

export const getUserServerErrorMessage = (error: HttpErrorResponse): string => {
  switch (error.status) {
    case 404: {
      return `Not Found: ${error?.message}`;
    }
    case 403: {
      return `Access Denied: ${error?.message}`;
    }
    case 500: {
      return `Internal Server Error: ${error?.message}`;
    }
    default: {
      return `Unknown Server Error: ${error?.message}`;
    }
  }
};
