<irembogov-navbar [navbarItems]="getTopNavBarLinkItemsForRole()">
  <irembogov-navbar-profile-section
    (profileClick)="handleProfileClick()"
    profile
    [username]="
      userProfile && userProfile.firstName ? userProfile.firstName : ''
    "
    [activeRole]="
      userProfile && userProfile.primaryRole.label
        ? userProfile.primaryRole.label
        : ''
    "
    (logout)="logoutUser()"
    [updatedProfile]="
      profilePicture
        ? 'data:' +
          profilePicture.contentType +
          ';base64,' +
          profilePicture.file
        : ''
    "></irembogov-navbar-profile-section>
</irembogov-navbar>

<router-outlet></router-outlet>
